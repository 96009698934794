module.exports = {
    associationName: `cquaa`,
    displayName: `Chongqing University Alumni Association`,
    siteUrl: `https://www.cquaa.ca`, // Site domain. Do not include a trailing slash!
    folderName: `www.cquaa.ca`,
    themeName: `ghost`,
    authors: [
        `john`,
        `stanley`,
    ],
    systemMenu: true,
    postsPerPage: 3, // Number of posts shown on paginated pages (changes this requires sometimes to delete the cache)
    title: `重庆大学加拿大校友会`,
    shortTitle: `cquaa`, // Used for App manifest e.g. Mobile Home Screen
    siteIcon: `cqulogo-small.png`, // Logo in /static dir used for SEO, RSS, and App manifest
    siteTitleMeta: `Chongqing University Alumni Association`, // This allows an alternative site title for meta data for pages.
    siteDescriptionMeta: `Chongqing University Alumni Association(Canada)`, // This allows an alternative site description for meta data for pages.

    //only posts with these tags will be shown
    tags: [`event`,`people`,`about`],
    navigation: [
        { label: `Home`, url: `/` },
        { label: `Events`, url: `/event` },
        { label: `People`, url: `/people` },
    ],
    tagsToDisplay: [
        {
            node: {
                slug: `event`,
                tagTitle: `Events`,
                tagDescription: `欢迎加入缤纷多彩的活动`,
                url: `http://cms.xiaoju.ca/tag/event/`,
                postCount: 5,
            },
        },
        {
            node: {
                slug: `people`,
                tagTitle: `People`,
                tagDescription: `校友风采展现`,
                url: `http://cms.xiaoju.ca/tag/people/`,
                postCount: 2,
            },
        },
    ],
    twitter: ``,
    facebook: ``,
    lang: `en`,
    shareImageWidth: 1000, // Change to the width of your default share image
    shareImageHeight: 523, // Change to the height of your default share image

    backgroundColor: `#e9e9e9`, // Used for Offline Manifest
    themeColor: `#15171A`, // Used for Offline Manifest
    googleTag: `GTM-5Q8ZW6H`,
}
